import request from "../../request/request";

// 获取前端类目树父节点集合
//订单列表
const getPaymentinformation = async (id) =>
  request({
    url: `/orderBusiness/getParentOrderBusiness?qp-parentOrderNo-eq=${id}`,
    method: "get",
  });
const paymentOrder = async () =>
  request({
    url: `/paymentOrder`,
    method: "post",
  });
  //查询支付单
const getPayOrder = async (params) =>
  request({
    url: `/paymentOrder/getPayOrder`,
    method: "get",
    params:params
  })
//跳转支付
const preparePay = async (params) =>
  request({
    url: `/jftPaymentOrder/preparePay`,
    method: "post",
    data:params
  })
//确认线下支付
const CashPay = async (params) =>
  request({
    url: `/paymentOrder/cashPay`,
    method: "post",
    data:params
  })
  export { getPaymentinformation ,paymentOrder,preparePay,getPayOrder,CashPay};
<template>
  <div id="content">
    <div class="content-layout">
      <div class="pay-head">
      <div class="pay-head-icon">
        <img src="../../assets/icon/icon__successful.svg" alt="" />
      </div>
      <div class="pay-head-txt">
        共{{ findLength() }}笔订单提交成功
      </div>
      <strong></strong>
      <div class="pay-head-time" >
        <div id="time">
          <div class="order-time" v-if="time.seconds" >
           <van-count-down
              class="timeColor"
              :time="getDateTime"
              @finish="finish"
            > 
              <template #default="timeData">
                <span class="back">订单将于</span>
                <span class="block">&nbsp;{{ timeData.days }}&nbsp;</span>
                <span class="colon">天</span>
                <span class="block">&nbsp;{{ timeData.hours }}&nbsp;</span>
                <span class="colon">时</span>
                <span class="block">&nbsp;{{ timeData.minutes }}&nbsp;</span>
                <span class="colon">分</span>
                <span class="block">&nbsp;{{ timeData.seconds }}&nbsp;</span>
                <span class="colon">秒</span>
                <span class="back">&nbsp;后自动关闭</span>
              </template>
            </van-count-down>
          </div>
        </div>
      </div>
      <div class="pay-amount">
        <div class="pay-amount-txt">应付总额：</div>
        <div class="pay-amount-price">
          ￥{{ (data.totalActualAmount||0).toFixed(2) }}
        </div>
      </div>
      </div>
    <div>
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell title="线下支付" clickable @click="chosepay">
            <template #right-icon>
              <van-radio name="1" :radio="1" />
            </template>
          </van-cell>
          <van-cell title="在线支付" clickable @click="chosepay">
            <template #right-icon>
              <van-radio name="2" :radio="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div v-if="paymoney" class="text-tips" >企业支付暂不支持移动端支付,如需企业支付请前往PC端订单列表完成支付</div>
      <div class="pay-order" v-for="item in data.orderList" :key="item.id" v-show = "showpay">
      <div class="pay-order-storeimg">
        <img :src="findlogo(item)" alt="">
        <div class="dianpu">{{ item.storeName }}</div>
      </div>
      <div></div>
      <div class="pay-order-content">
        <div class="pay-order-content-sum">
          请向以下商家汇款
          <p>￥{{ (item.actualAmount || 0).toFixed(2) }}</p>
          用于支付货款
        </div>
        <div class="pay-order-content-accountname">
          户 &nbsp;&nbsp;&nbsp;名 :
          <p>{{ item.bank.name }}</p>
        </div>
        <div class="pay-order-content-bank">
          开户行 :
          <p>{{ item.bank.bankName }}</p>
        </div>
        <div class="pay-order-content-account">
          账 &nbsp;&nbsp;&nbsp;户 :
          <p> {{ item.bank.bankAccount }} </p>
        </div>
      </div>
      </div>
      <van-dialog
      v-model="show"
      title="标题"
      show-cancel-button
      @confirm="tohome"
    >
      <div class="dialog-close">订单已取消</div>
      </van-dialog>
    </div>
    <div class="button" v-show = "showpay">
      <van-button
       @click="know"
        size="large" 
        type="danger" 
        class="button-know"
      >
       已完成支付
      </van-button>
    </div>
      <div class="button" v-show = paymoney>
      <van-button
        size="large" 
        type="danger" 
        class="button-know"
        @click="onlinePay"
      >
       立即支付
      </van-button>
    </div>
    <van-loading size="24px" v-show="payinfo" vertical>加载中...</van-loading>
  </div>
</template>
<script>
import { Toast } from 'vant';
import { getPaymentinformation, paymentOrder,preparePay,getPayOrder,CashPay} from "./service";
export default {
  data() {
    return {
      show: false,
      payinfo: false,
      radio:"1",
      paymoney:false,
      showpay:true,
      time: {},
      data: {},
      companyCodeList:[],
      paymentNoList:[]
    };
  },
  mounted() {
    this.time = "";
    this.getInformation(this.$route.query.id);
  },
  computed: {
    getDateTime() {
      return (
        parseInt(this.time.day) * 3600 * 24 * 1000 +
        parseInt(this.time.hour) * 3600 * 1000 +
        parseInt(this.time.minutes) * 60 * 1000 +
        parseInt(this.time.seconds) * 1000
      );
    },
  },
  methods: {
    //支付的方式
    async chosepay(){
      if(this.radio == 1) {
        this.showpay = true
        this.paymoney = false
      } else {
        this.showpay = false
        this.paymoney = true
      }
    },
    //线上支付
    async onlinePay(){
      let params ={
        'qp-businessNo-eq':this.$route.query.orderNo,
        'pageSize':10000
      }
      const res = await getPayOrder(params)
      if(res.status === "0"){
        res.data.list.forEach(item => {
          this.companyCodeList.push(item.payeeCode)
          this.paymentNoList.push(item.paymentNo)
        });
        let data ={
            "channelCode": "80",
            "jumpUrl": "https://www.baidu.com/home",
            "paymentNoList": this.paymentNoList,
            "sceneType": 50,
            "storeCode":this.$route.query.storeCode
        }
        const res1 =await preparePay(data).then((response)=>{
            this.htmldata = response;
            this.openWin(this.htmldata)
          })
      }
      this.companyCodeList=[];
      this.paymentNoList = []    
    },
    //打开新窗口
    openWin(html){
      let myWindow = window.open();
      myWindow.document.write(html);
      myWindow.focus();
    },
    //店铺logo
    findlogo(item){
      return item?.ext ? JSON.parse(JSON.parse(item.ext).logo)[0]?.thumbUrl : 'http://www.liby.com.cn/images/logo.png'
    },
    findLength(){
      return this.data?.orderList?.length?this.data.orderList.length:0
    },
    finish() {
      this.show = true;
      this.$router.push("/home");
    },
    tohome() {
      this.$router.push("/home");
    },
    async know() {
      let params ={
        'qp-businessNo-eq':this.$route.query.orderNo,
        'pageSize':10000
      }
      const res = await getPayOrder(params);
      if(res.status === '0'){
        res.data.list.forEach(item => {
          this.companyCodeList.push(item.payeeCode)
          this.paymentNoList.push(item.paymentNo)
        });
      }
      let data ={
          "channelCode": "900",
          "companyCodeList": this.companyCodeList,
          "jumpUrl": "https://www.baidu.com/home",
          "paymentNoList": this.paymentNoList,
          "sceneType": 50,
          "storeCode":this.$route.query.storeCode
        }
      const res1 =await CashPay(data)
      if(res1.status === '0'){
        Toast("确认线下支付成功")
        this.$router.push("/mineorder")
      }else{
        Toast(res1.msg)
      }
      this.companyCodeList=[];
      this.paymentNoList = []    
    },
    async getInformation(id) {
      this.payinfo = true;
      const res = await getPaymentinformation(id);
      this.payinfo = false;
      if (res.status == "0") {
        this.data = res.data;
        this.time = res.data.orderList[0];
      }
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
</style>
<style lang="less" scoped>
/deep/ #time {
  width: 100%;
  .order-time {
    width: 100%;
    height: 40px;
    opacity: 1;
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    text-align: center;
    color: #1c2529;
    line-height: 20px;
    .van-count-down {
      text-align: center;
      line-height: 40px;
      font-weight: 400 ;
      font-size: 12px;
      color: #00cc47;
      p {
        margin: 0 auto;
      }
    }
  }

  .back {
    color: #1c2529;
  }
}
#content {
  background-color: #f5f6f7;
  padding-top: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .content-layout{
     flex: 1 1 0;
     height: 100%;
     overflow: auto;
  }
  .button {
     width: 100%;
     height: 82px;
     background: #fff;
     text-align: center;
  }
}
.pay-head {
  width: 351px;
  height: 200px;
  opacity: 1;
  background: #ffffff;
  border-radius: 8px;
  margin: 8px 12px;
  padding-top: 14px;
  .pay-head-icon {
    width: 62px;
    height: 62px;
    opacity: 1;
    text-align: center;
    margin: 0 auto 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .pay-head-txt {
    // width: 172px;
    height: 24px;
    opacity: 1;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    color: #1c2529;
    line-height: 24px;
    margin: 6px 98px 6px 98px;
  }
  .pay-amount {
    text-align: center;
    // margin-top: 10px;
    &-txt {
      width: 60px;
      height: 20px;
      opacity: 0.5;
      font-size: 12px;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      text-align: right;
      color: #1c2529;
      line-height: 20px;
      display: inline-block;
    }
    &-price {
      width: 66px;
      height: 24px;
      opacity: 1;
      font-size: 16px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      text-align: left;
      color: #ff2731;
      line-height: 24px;
      display: inline-block;
    }
  }
}
.pay-order {
  width: 351px;
  min-height: 190px;
  opacity: 1;
  background: #ffffff;
  border-radius: 8px;
  margin: 8px 12px;
  .pay-order-storeimg {
    display: flex;
    align-items: center;
    height: 56px;
    opacity: 1;
    background: #ffffff;
    border-bottom: 1px solid #b8bcbe;
    width: 95%;
    margin: 0 auto;
    border-radius: 8px 8px 0 0;
    display: flex;
    img {
      width: 32px;
      height: 32px;
      margin: 12px 8px 12px 12px;
      border-radius: 4px;
    }
    .dianpu {
      min-height: 20px;
      opacity: 1;
      font-size: 16px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      text-align: left;
      color: #000000;
      line-height: 20px;
      display: inline-block;
      margin: auto 0;
    }
  }
  .pay-order-content {
    margin-left: 10%;
    .pay-order-content-sum {
      color: #1c2529;
      font-size: 14px;
      min-height: 25px;
      p {
        font-family: "Montserrat", sans-serif;
        color: #fd6653;
        display: inline-block;
      }
    }
    .pay-order-content-accountname {
      min-height: 30px;
      color: #1c2529;
      font-size: 14px;
      display: flex;
      p {
        color: #f5a804;
        display: inline-block;
         width: 255px;
        margin: 0;
        margin-left: 8px;
      }
    }
    .pay-order-content-bank {
      min-height: 30px;
      color: #1c2529;
      font-size: 14px;
      display: flex;
      p {
        color: #f5a804;
        display: inline-block;
        width: 255px;
        margin: 0;
        margin-left: 8px;
      }
    }
    .pay-order-content-account {
      min-height: 30px;
      color: #1c2529;
      font-size: 14px;
      display: flex;
      padding-bottom: 14px;
      p {
        color: #f5a804;
        width: 255px;
        margin: 0;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin-left: 8px;
      }
    }
  }
}
.dialog-close {
  text-align: center;
}
.pay-height{
  height: 82px;
}
.button-know {
  border-radius: 12px;
  margin-top: 12%;
  width: 90%;
  margin: 5%;
  background: #0095da;
  border: none;
}

.pay-order-content-accountnamespan {
  margin-right: 10px;
}
.pay-order-content-accountnamespans {
  width: 50px;
  margin-right: 8px;
}
/deep/.van-cell-group{
  margin: 0 12px;
  border-radius: 8px;
}
.text-tips{
    font-size: 12px;
    color: #1c2529;
    opacity: 0.5;
    width: 351px;
    margin: 0 auto;
    padding: 10px;
}
</style>
